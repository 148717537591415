<template>
  <b-badge
    v-if="isMoreThanWeek"
    v-b-tooltip.hover.top="'Last successful generation is more than one week'"
    variant="danger"
  >
    {{ value }}
  </b-badge>
  <vx-no-info-dash v-else-if="!value" />
  <span v-else>{{ value }}</span>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BBadge } from 'bootstrap-vue'
import { differenceInCalendarDays } from 'date-fns'
import { dateFormat } from '@/services/utils/date'
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'VxCellCaseGenerationTooltip',
  components: { BBadge, VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const week = 7

    const value = computed(() => {
      if (!data.value) return null

      return dateFormat(data.value)
    })

    const isMoreThanWeek = computed(() => {
      if (!data.value) return false

      return differenceInCalendarDays(new Date(), new Date(data.value)) > week
    })

    return {
      value,
      isMoreThanWeek
    }
  }
}
</script>
