<template>
  <b-form-checkbox
    :id="name"
    v-model="innerValue"
    @change="handleChange"
  />
</template>

<script>
import { inject, computed } from '@vue/composition-api'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'VxCellPaymentCountryCheckboxInner',
  components: {
    BFormCheckbox
  },
  props: {
    columnKey: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  setup ({ columnKey, item }) {
    const { changeCell, innerItems } = inject('checkboxStore')

    const innerValue = computed({
      get: () => innerItems.value[item.id][columnKey],
      set: () => {}
    })
    const name = `${item.id}-${columnKey}`

    const handleChange = (value) => {
      changeCell(value, item.id, columnKey, item)
    }

    return {
      innerItems,
      innerValue,
      handleChange,
      name
    }
  }
}
</script>
