var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[_c('h4',[_vm._v("Sort")]),_c('div',{staticClass:"sort-container"},[_c('vx-select',{attrs:{"value":_vm.value.sortBy,"options":_vm.sortByOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options-label":"label","placeholder":"Sort by","name":"sortBy","clearable":""},on:{"input":function($event){return _vm.update('sortBy', $event)}}}),_c('vx-select',{attrs:{"value":_vm.value.sortDesc,"options":_vm.sortDescOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"disabled":_vm.sortDescIsDisabled,"options-label":"label","placeholder":"Order","name":"sortDesc"},on:{"input":function($event){return _vm.update('sortDesc', $event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }