<template>
  <div :class="{'vx-cell-text-copy': !hideText}">
    <vx-no-info-dash v-if="!data.value && !emptyText" />
    <span v-else-if="!data.value && emptyText">{{ emptyText }}</span>
    <div v-else class="d-flex align-items-center flex-row">
      <div
        v-if="!hideText"
        v-b-tooltip.hover.top="data.value"
        class="text-truncate"
      >
        {{ data.value }}
      </div>
      <vx-button-copy :text-to-copy="data.value" :class="{'ml-1': !hideText}" />
    </div>
  </div>
</template>

<script>
import { VxButtonCopy } from '@/components/buttons'
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { tdAttrParse } from '@/services/table'

export default {
  name: 'VxCellTextCopy',
  components: {
    VxButtonCopy,
    VxNoInfoDash
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const { emptyText, hideText = false } = tdAttrParse(data, { emptyText: '' })
    return {
      emptyText,
      hideText
    }
  }
}
</script>

<style lang="scss" scoped>
.vx-cell-text-copy {
  width: 180px;
}
</style>
