<template>
  <div ref="targetEl">
    <slot v-if="shouldRender" />
  </div>
</template>

<script>
import { onBeforeUnmount, ref } from '@vue/composition-api'
import { useIntersectionObserver } from '@vueuse/core'

export default {
  name: 'VxLazy',
  setup () {
    const shouldRender = ref(false)
    const targetEl = ref()

    const { stop } = useIntersectionObserver(
      targetEl,
      ([{ isIntersecting }]) => {
        shouldRender.value = isIntersecting
      },
      {
        rootMargin: '500px'
      }
    )

    onBeforeUnmount(() => {
      stop()
    })

    return {
      targetEl,
      shouldRender
    }
  }
}
</script>
