<template>
  <vx-no-info-dash v-if="!hasBonus" />
  <div v-else>
    <b-link :to="route">
      {{ name }}
    </b-link>
    <div v-html="description" />
    <b-badge
      v-if="minegateStatus"
      :variant="minegateStatus.color"
    >
      {{ minegateStatus.status }}
    </b-badge>
  </div>
</template>

<script>
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { bonusStatuses, paymentSystemsMap } from '@/services/resources'
import { buildRoute, path } from '@/router'
import { tdAttrParse } from '@/services/table'
import { addPercentSign, moneyFormat } from '@/services/utils'
import { BBadge, BLink } from 'bootstrap-vue'

export default {
  name: 'VxCellRefillBonus',
  components: { VxNoInfoDash, BLink, BBadge },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const hasBonus = !!data.value

    const { id, type, name, value, min_refill_amount } = data.value
    const { gateway = null, status = null } = tdAttrParse(data)

    const route = buildRoute(path.refillBonuses, { query: { id } })

    const description = () => {
      const formatValue = type === 'fixed'
        ? moneyFormat(value)
        : addPercentSign(value)
      return `<b>${formatValue}</b> for <b>${moneyFormat(min_refill_amount)}</b> min refill sum`
    }

    const minegateStatus = () => {
      if (gateway !== paymentSystemsMap.minegate) return null

      const statusColors = {
        [bonusStatuses.applied]: 'success',
        [bonusStatuses.desired]: 'primary',
        [bonusStatuses.ignored]: 'danger'
      }

      return {
        status,
        color: statusColors[status]
      }
    }

    return {
      hasBonus,
      name,
      route,
      minegateStatus: minegateStatus(),
      description: description()
    }
  }
}
</script>
