<template>
  <span :class="textColor">
    {{ value }}
  </span>
</template>

<script>
import { credentialStatuses, credentialTypes } from '@/services/resources'
import { ref } from '@vue/composition-api'

const valueThreshold = 250
const textDanger = 'text-danger'

export default {
  name: 'VxCellCredentialsExtraPoints',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const textColor = ref('')

    const { status, type } = data.item
    const value = data.value

    const credentialCondition = status === credentialStatuses.unsafe && type === credentialTypes.bin

    if (credentialCondition && value >= valueThreshold) {
      textColor.value = textDanger
    }

    return {
      value,
      textColor
    }
  }
}
</script>
