<template>
  <vx-no-info-dash v-if="!formattedMoney" />
  <span v-else>{{ formattedMoney }}</span>
</template>

<script>
import { moneyFormat } from '@/services/utils'
import { tdAttrParse } from '@/services/table'
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellMoney',
  components: { VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { money } = tdAttrParse(props.data)
    const formattedMoney = computed(() => {
      return moneyFormat(money || props.data.value, { withSpace: false })
    })

    return {
      formattedMoney
    }
  }
}
</script>
