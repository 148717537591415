<template>
  <div>
    {{ valueComputed }}
  </div>
</template>

<script>
import { tdAttrParse } from '@/services/table'
import { useResource } from '@/services/resources'
import { computed, ref } from '@vue/composition-api'
import { isFunction } from 'lodash'

export default {
  name: 'VxCellResource',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { resource, responseMapper } = tdAttrParse(props.data)

    const { can, callRequest } = useResource(resource)
    const resourceResponse = ref(null)

    const valueComputed = computed(() => {
      const { value } = props.data
      if (!can || !resourceResponse.value) return value

      return responseMapper && isFunction(responseMapper)
        ? responseMapper(resourceResponse.value) || value
        : value
    })

    const applyRequest = async () => {
      const [err, res] = await callRequest()
      if (err) return

      resourceResponse.value = res
    }

    applyRequest()

    return {
      can,
      valueComputed
    }
  }
}
</script>
