<template>
  <b-button-group :vertical="vertical">
    <vx-button
      v-for="(button, idx) of formattedButtons"
      :key="idx"
      v-bind="button"
      @click="handleClick(button)"
    >
      <template v-if="button.label">
        {{ button.label }}
      </template>
    </vx-button>
  </b-button-group>
</template>

<script>
import { VxButton } from '@/components/buttons'
import { computed } from '@vue/composition-api'
import { toArray } from '@/services/utils'
import { BButtonGroup } from 'bootstrap-vue'
import { tdAttrParse } from '@/services/table'
import { useResource } from '@/services/resources'

const defaultButtonProperties = {
  size: 'sm',
  can: true
}
// TODO: make responsive
// TODO: not pass onClick to component in template

/**
 * tdAttr.vertical = true
 *  Displays buttons vertically
 *
 * tdAttr.buttons
 * Resource
 *  After the resource is executed
 *    table will be updated
 *    onclick attribute will be called with the response from the resource
 *  Example:
 *    tdAttr: () => ({
 *       buttons: [
 *         {
 *           ...buttons.edit,
 *           resource: someDomainResource.edit,
 *           onClick: (item, resourceResponse) => {}
 *         }
 *       ]
 *     })
 */
export default {
  name: 'VxCellButtons',
  components: { VxButton, BButtonGroup },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const { item } = props.data
    const tdAttr = computed(() => {
      return tdAttrParse(
        props.data,
        { buttons: [], vertical: false }
      )
    })

    const formattedButtons = computed(() => {
      return toArray(tdAttr.value.buttons)
        .map((button) => {
          const formattedButton = {
            ...defaultButtonProperties,
            ...button
          }

          const { resource } = button
          if (!resource) return formattedButton

          const { can, loading, callRequest } = useResource(resource)

          return {
            ...formattedButton,
            can,
            loading,
            callRequest
          }
        })
    })

    const handleClick = async ({ onClick, to, callRequest }) => {
      if (to) return

      if (callRequest) {
        const [err, res] = await callRequest()
        if (!err) {
          props.refreshData()
        }
        if (onClick) {
          onClick(item, [err, res])
        }
        return
      }

      if (onClick) {
        onClick(item)
      }
    }

    return {
      vertical: tdAttr.value.vertical,
      formattedButtons,
      handleClick
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-group {
  & > div:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.btn-group-vertical {
  & > div {
    width: 100%;

    button {
      text-align: center;
    }

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
