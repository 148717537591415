<template>
  <vx-checkbox
    :value="value"
    :name="name"
    class="cursor-pointer"
    @input="update"
  />
</template>

<script>
import VxCheckbox from '@/components/form/VxCheckbox'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellSelectableCheckbox',
  components: { VxCheckbox },
  props: {
    data: {
      type: Object,
      required: true
    },
    tableId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      tableId,
      data: {
        index,
        field: { key },
        selectRow,
        unselectRow
      }
    } = props
    const name = `${tableId}-${key}-${index}`

    const value = computed(() => props.data.rowSelected)

    const update = (value) => {
      value ? selectRow() : unselectRow()
    }

    return {
      name,
      update,
      value
    }
  }
}
</script>
