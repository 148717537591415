// node by class that allows to be draggable
const handleItem = '.draggable-item'

// advanced .push (by index). Util fn
const insertAt = (array, index, ...elementsArray) => {
  array.splice(index, 0, ...elementsArray)
}

let previousOrder

const createSortable = async (el, options, vnode) => {
  const { Sortable } = await import('sortablejs')
  const { sortableEmitter } = options

  return Sortable.create(el, {
    ...options,
    animation: 150,
    handle: handleItem,
    onEnd: ({ oldIndex, newIndex, item: { dataset } }) => {
      // row id
      const rowItemId = Number(dataset.pk)
      // original items list (with order)
      const list = previousOrder || [...vnode.context.items]
      // find item to reorder
      const itemToMove = list.filter(item => rowItemId === item.id)
      // filter list without reordered item
      const listWithoutItem = list.filter((item, key) => key !== oldIndex)
      // mutate list. Set reordered item by new index to pool
      insertAt(listWithoutItem, newIndex, ...itemToMove)
      // cast list to output interface
      const toOutputInterface = listWithoutItem.map(({ id }, key) => ({ id, position: key + 1 }))
      // send to callback casted list
      sortableEmitter(toOutputInterface)
      // save order state
      previousOrder = listWithoutItem
    }
  })
}

export const sortable = {
  name: 'sortable',
  bind (el, binding, vnode) {
    // check props.sortable
    if (!binding.value.sortable) return null

    const table = el
    table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode)
  },
  update (el, binding) {
    if (binding.value.resetSortableCache) {
      previousOrder = null
    }
  }
}
