<template>
  <div
    v-if="tdAttr.show"
    class="h-100 w-100 cursor-pointer d-flex justify-content-center"
    @click="data.toggleDetails"
  >
    <feather-icon
      :icon="icon"
      size="18"
    />
  </div>
</template>

<script>
import VxButton from '@/components/buttons/VxButton'
import { computed } from '@vue/composition-api'
import { tdAttrParse } from '@/services/table'

export default {
  name: 'VxCellDetailsToggler',
  components: { VxButton },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const icon = computed(() => {
      return props.data.detailsShowing ? 'ChevronDownIcon' : 'ChevronRightIcon'
    })
    const tdAttr = computed(() => {
      return tdAttrParse(props.data, { show: true })
    })

    return {
      icon,
      tdAttr
    }
  }
}
</script>
