<template>
  <b-form>
    <h4>Sort</h4>
    <div class="sort-container">
      <vx-select
        :value="value.sortBy"
        :options="sortByOptions"
        :reduce="({ value }) => value"
        options-label="label"
        placeholder="Sort by"
        name="sortBy"
        clearable
        @input="update('sortBy', $event)"
      />
      <vx-select
        :value="value.sortDesc"
        :options="sortDescOptions"
        :reduce="({ value }) => value"
        :disabled="sortDescIsDisabled"
        options-label="label"
        placeholder="Order"
        name="sortDesc"
        @input="update('sortDesc', $event)"
      />
    </div>
  </b-form>
</template>

<script>
import { BForm } from 'bootstrap-vue'
import { VxSelect } from '@/components/form'
import { computed } from '@vue/composition-api'
import { isParamEmpty } from '@/services/table'

export default {
  name: 'VxTableSort',
  components: {
    VxSelect,
    BForm
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    sortByOptions: {
      type: Array,
      required: true
    }
  },
  setup (props, ctx) {
    const sortDescIsDisabled = computed(() => {
      return isParamEmpty(props.value.sortBy)
    })
    const sortOrderingMap = {
      Asc: false,
      Desc: true
    }
    const sortDescOptions = Object.keys(sortOrderingMap).map(key => {
      return { label: key, value: sortOrderingMap[key] }
    })

    const update = (key, value) => {
      const makeEmit = (payload) => {
        ctx.emit('input', { ...props.value, ...payload })
      }

      switch (true) {
        case (key === 'sortBy' && isParamEmpty(value)):
          makeEmit({ sortBy: null, sortDesc: null })
          break
        case (
          key === 'sortBy' &&
          !isParamEmpty(value) &&
          isParamEmpty(props.value.sortDesc)
        ):
          makeEmit({ [key]: value, sortDesc: sortOrderingMap.Desc })
          break
        default:
          makeEmit({ [key]: value })
      }
    }

    return {
      sortDescOptions,
      sortDescIsDisabled,
      update
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-container {
  display: flex;

  .form-group:nth-child(1)::v-deep {
    width: 60%;

    .vs__dropdown-toggle {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .form-group:nth-child(2)::v-deep {
    width: 40%;

    .vs__dropdown-toggle {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
