<template>
  <div class="d-flex flex-column vertical-list">
    <div v-for="(item, id) in items" :key="id">
      {{ item }}
    </div>
  </div>
</template>

<script>
import { toArray } from '@/services/utils'

export default {
  name: 'VxCellList',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value } }) {
    return {
      items: toArray(value)
    }
  }
}
</script>
