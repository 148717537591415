<template>
  <div>
    <b-card v-if="hasFilters">
      <vx-table-filters
        v-model="filtersValue"
        :filters="filterDefs"
        :filter-input-width="filterInputWidth"
      />
    </b-card>
    <vx-card
      no-body
      card-class="mb-0"
    >
      <template v-if="$slots.title" #title>
        <slot name="title" />
      </template>
      <template v-if="$slots.actions" #actions>
        <slot name="actions" />
      </template>

      <vx-table-body
        ref="tableBodyRef"
        :columns="columns"
        :items="items"
        :loading="loading"
        :primary-key="primaryKey"
        :per-page="pagination.perPage"
        :current-page="pagination.page"
        :filter="filterValueToTableMapper"
        :filter-function="filterComparator"
        v-bind="$attrs"
        @row-selected="onRowSelected"
      />

      <template v-if="hasPagination" #footer>
        <vx-table-pagination
          v-model="pagination"
          :params="paginationParams"
          :hide-per-page="hidePerPage"
        />
      </template>
    </vx-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { VxTableBody } from '@/components/table'
import VxTablePagination from '@/components/table/vx-table-pagination/VxTablePagination'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { useLocalFilters, useLocalPagination } from '@/services/table'
import VxTableFilters from '@/components/table/vx-table-filters/VxTableFilters'
import VxCard from '@/components/VxCard'

export default {
  name: 'VxTableSimple',
  components: {
    BCard,
    VxCard,
    VxTableFilters,
    VxTableBody,
    VxTablePagination
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      default: () => ([])
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    loading: Boolean,
    hasPagination: Boolean,
    hidePerPage: Boolean,
    perPage: {
      type: Number,
      default: 25
    },
    filterInputWidth: {
      type: Number,
      default: 3
    }
  },
  setup (props, { emit }) {
    const { perPage, hasPagination } = props

    const tableBodyRef = ref(null)

    const refreshTable = () => {
      tableBodyRef.value.refreshTable()
    }

    const filteredItemsLength = computed(() => {
      return tableBodyRef.value?.filteredItemsLength
    })

    const filtersValue = reactive({})

    const {
      hasFilters,
      filterDefs,
      filterValueToTableMapper,
      setFilters,
      filterComparator
    } = useLocalFilters(filtersValue, props.filters)

    const {
      pagination,
      paginationParams,
      updatePaginationParams
    } = useLocalPagination(hasPagination, perPage, filteredItemsLength.value)

    const init = () => {
      setFilters()
      if (hasPagination) {
        updatePaginationParams()
        watch(
          () => [pagination, filteredItemsLength.value],
          () => {
            updatePaginationParams(filteredItemsLength.value)
          },
          { deep: true }
        )
      }
    }

    const onRowSelected = (items) => {
      emit('row-selected', items)
    }

    init()

    return {
      tableBodyRef,
      refreshTable,

      pagination,
      paginationParams,

      hasFilters,
      filterDefs,
      filterValueToTableMapper,
      filtersValue,
      filterComparator,

      onRowSelected
    }
  }
}
</script>
