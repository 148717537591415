<template>
  <vx-no-info-dash v-if="!formattedPercentage" />
  <span v-else>{{ formattedPercentage }}</span>
</template>

<script>
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { addPercentSign, percentageFormat } from '@/services/utils'
import { tdAttrParse } from '@/services/table'

export default {
  name: 'VxCellPercentage',
  components: { VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const { alreadyInteger = false, fractionDigits = 2 } = tdAttrParse(data)
    const { value } = data

    const formattedPercentage = value || value === 0
      ? (alreadyInteger ? addPercentSign(value) : percentageFormat(value, fractionDigits))
      : value

    return {
      formattedPercentage
    }
  }
}
</script>
