<template>
  <div class="badges-container">
    <b-badge
      v-for="(item, index) in value"
      :key="index"
      v-b-tooltip.hover.top="tooltipText(item)"
      :variant="variant(item)"
    >
      {{ label(item) }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { toArray } from '@/services/utils'
import { tdAttrParse } from '@/services/table'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellBadges',
  components: {
    BBadge
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const {
      labelKey = null,
      tooltipKey = null,
      colors = null,
      defaultVariant = 'primary'
    } = tdAttrParse(props.data)

    const value = computed({
      get: () => props.data.value === '' ? [] : toArray(props.data.value),
      set: (val) => val
    })

    const label = (item) => {
      return labelKey ? item[labelKey] : item
    }

    const tooltipText = (item) => {
      return tooltipKey ? item[tooltipKey] : null
    }

    const variant = (item) => {
      if (!colors) return defaultVariant

      return colors[label(item)] || defaultVariant
    }

    return {
      value,
      label,
      tooltipText,
      variant
    }
  }
}
</script>

<style scoped>
.badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
