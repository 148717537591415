<template>
  <feather-icon
    size="22"
    :icon="iconAttr.icon"
    :class="iconAttr.class"
  />
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  name: 'VxCellPaymentCountryIconStatus',
  props: {
    columnKey: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  setup ({ columnKey, item }) {
    const { innerItems } = inject('checkboxStore')
    const value = innerItems.value[item.id][columnKey]

    const iconAttrs = {
      success: {
        icon: 'CheckIcon',
        class: 'text-success'
      },
      danger: {
        icon: 'XIcon',
        class: 'text-danger'
      }
    }

    const iconAttr = value ? iconAttrs.success : iconAttrs.danger

    return {
      iconAttr
    }
  }
}
</script>
