import { reactive, ref, watch, nextTick, onUnmounted } from '@vue/composition-api'
import { isEqual, mapKeys, snakeCase } from 'lodash'
import router, { routerQueryIsEqualWith } from '@/router'
import { useResource } from '@/services/resources'
import { usePagination, useFilters, useSorting } from '@/services/table'
import { useCache } from '@/services/utils'

/**
 * @param resource
 * @param filters
 * @param columns
 * @param dataMapper
 * @param prefetch
 * @param perPage
 * @param suppressRouteHandling
 * @param ctx
 * @returns {{
 *    filterDefs: Ref<UnwrapRef<[]>>,
 *    paginationParams: Ref<UnwrapRef<{total: number, from: number, to: number}>>,
 *    hasPagination: Ref<UnwrapRef<boolean>>,
 *    hasFilters: (*|boolean),
 *    queryParams: UnwrapRef<{
 *      filter: {},
 *      pagination: {perPage: number, page: number},
 *      sort: {sortDesc: null, sortBy: null}
 *    }>,
 *    refresh: ((function(): Promise<void>)|*),
 *    loading: Ref<UnwrapRef<boolean>>,
 *    items: Ref<UnwrapRef<*[]>>,
 *    primaryKey: string
 * }}
 */
export default function useVxTable ({
  resource,
  filters,
  columns,
  dataMapper,
  prefetch,
  perPage,
  suppressRouteHandling
}, ctx) {
  let watcher = null
  const primaryKey = 'id'
  const items = ref([])

  const queryParams = reactive({
    pagination: {
      perPage: perPage || 25,
      page: 1
    },
    sort: {
      sortBy: null,
      sortDesc: null
    },
    filter: {}
  })

  const {
    hasPagination,
    paginationParams,
    updatePaginationParams,
    updateCurrentPage,
    queryToPaginationMapper,
    paginationToQueryMapper,
    paginationToBackMapper
  } = usePagination(queryParams.pagination)

  const {
    filterDefs,
    hasFilters,
    setFilters,
    queryToFilterMapper,
    filterToQueryMapper,
    filterToBackMapper
  } = useFilters(queryParams.filter, filters)

  const {
    setInitialSorting,
    queryToSortingMapper,
    sortingToQueryMapper,
    sortingToBackMapper
  } = useSorting(queryParams.sort, columns)

  // store old value of queryPrams because currentValue = oldValue in watch reactive
  const { cache, updateCache } = useCache({})

  const { loading, can, callRequest, requestParams } = useResource(resource)

  const init = async () => {
    if (!can) return

    if (prefetch) {
      loading.value = true
      await prefetch()
    }
    setInitialSorting()
    setFilters()
    updateQueryParamsFromUrl()
    await updateTableData(queryParams, true)
    await setQueryParamsToUrl()
    updateCache(queryParams)

    watcher = watch(
      queryParams,
      async (value) => {
        if (hasChangingEffect(value)) return

        await updateTableData(value)
        await setQueryParamsToUrl()
        updateCache(value)
      },
      {
        deep: true
      }
    )
  }

  const hasChangingEffect = (current) => {
    const filtersIsEqual = isEqual(current.filter, cache.value.filter)

    // go to first page if filter changed
    if (!filtersIsEqual && current.pagination.page !== 1) {
      current.pagination.page = 1
      updateCache(current)
      return true
    }

    return false
  }

  const updateTableData = async (payload, initialUse = false) => {
    items.value = []

    const [error, res] = await callRequest(frontToBackMapper())
    if (error) return

    const { data, total, from, to, current_page } = res

    if (dataMapper) {
      items.value = await dataMapper(data)
    } else {
      items.value = data
    }

    updatePaginationParams({ total, from, to }, initialUse)

    // initial page should be setting after total
    // otherwise the pagination component will update page to 1 automatically
    await nextTick()
    if (initialUse) {
      // eslint-disable-next-line camelcase
      updateCurrentPage(current_page)
    }
  }

  const frontToBackMapper = () => {
    const paramsPayload = {
      ...requestParams.params,
      ...paginationToBackMapper(),
      ...sortingToBackMapper(),
      filter: {
        ...requestParams?.params?.filter || {},
        ...filterToBackMapper()
      }
    }

    const params = mapKeys(paramsPayload, (value, key) => snakeCase(key))

    return {
      ...requestParams,
      params
    }
  }

  // Parse and setting query params from URL to internal store
  const updateQueryParamsFromUrl = () => {
    if (suppressRouteHandling) return

    const { query } = router.currentRoute

    queryToFilterMapper(query)
    queryToPaginationMapper(query)
    queryToSortingMapper(query)
  }

  // Updating query URL from internal store
  const setQueryParamsToUrl = async () => {
    if (suppressRouteHandling) return

    const query = {
      ...paginationToQueryMapper(),
      ...filterToQueryMapper(),
      ...sortingToQueryMapper()
    }

    if (routerQueryIsEqualWith(query)) return

    await router.replace({ query })
  }

  const refresh = async () => {
    await updateTableData(queryParams)
  }

  const sortableEmitter = (sortedItems) => ctx.emit('sortableCallback', sortedItems)

  init().then()

  onUnmounted(() => {
    // stop watching
    if (watcher) {
      watcher()
    }
  })

  return {
    items,

    can,
    loading,

    primaryKey,

    hasPagination,
    paginationParams,

    filterDefs,
    hasFilters,

    queryParams,
    refresh,

    sortableEmitter
  }
}
