<template>
  <b-form-checkbox
    :id="name"
    v-model="innerValue"
    @change="handleChange"
  />
</template>

<script>
import { inject, computed } from '@vue/composition-api'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'VxCellPermissionsCheckboxInner',
  components: {
    BFormCheckbox
  },
  props: {
    permissionsId: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const { updateItem, innerItems } = inject('permissionsStore')

    const innerValue = computed({
      get: () => {
        return innerItems[props.permissionsId]
      },
      set: () => {}
    })
    const name = `${props.permissionsId}`

    const handleChange = (value) => {
      updateItem(props.permissionsId, value)
    }

    return {
      innerValue,
      handleChange,
      name
    }
  }
}
</script>
