<template>
  <div>
    <div v-for="(item, key) in items" :key="key">
      <b-link
        v-if="!item.hideLabel"
        v-bind="linkProps(item.entity_id)"
        class="d-flex flex-column font-weight-bold  mb-1 mt-1"
      >
        {{ item.entity.title }}
        <small
          v-b-tooltip.hover
          class="w-mc"
          :class="getPriceColor(item.main_price.delta_percentage)"
          :title="tooltipText(item.main_price.delta_percentage)"
        >
          {{ getSignedPrice(item.main_price.delta_percentage) }}
        </small>
      </b-link>
      <span
        v-else
        v-b-tooltip.hover
        class="w-mc"
        :class="getPriceColor(item.main_price.delta_percentage)"
        :title="tooltipText(item.main_price.delta_percentage)"
      >
        {{ getSignedPrice(item.main_price.delta_percentage) }}
      </span>
    </div>
  </div>
</template>

<script>
import { BAvatar, BLink, BMedia } from 'bootstrap-vue'
import { buildRoute, path } from '@/router'

export default {
  name: 'VxCellLuckyRollPrizes',

  components: {
    BAvatar,
    BMedia,
    BLink
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup ({ data }) {
    const linkProps = (id) => {
      return {
        to: buildRoute(path.items, { query: { id } })
      }
    }

    const colors = {
      low: 'text-success',
      mid: 'text-warning',
      high: 'text-danger'
    }

    const limits = {
      low: 10,
      mid: 20
    }

    const getSignedPrice = (price) => {
      const deltaSign = Math.sign(price)
      const sign = {
        0: `${price} %`,
        '-1': `${price} %`,
        1: `+${price} %`
      }

      return sign[deltaSign]
    }

    const tooltipText = (price) => {
      const deltaSign = Math.sign(price)
      const sign = {
        0: `Current price is equals initial. Delta: ${price} %`,
        '-1': `Current price is less than initial. Delta: ${price} %`,
        1: `Current price is more than initial. Delta: ${price} %`
      }

      return sign[deltaSign]
    }

    const getPriceColor = (price) => {
      const convertedPrice = Math.abs(price)

      switch (true) {
        case (convertedPrice <= limits.low):
          return colors.low
        case (convertedPrice > limits.low && convertedPrice < limits.mid):
          return colors.mid
        case (convertedPrice >= limits.mid):
          return colors.high
      }
    }

    return {
      items: data.value,
      linkProps,
      tooltipText,
      getSignedPrice,
      getPriceColor
    }
  }
}
</script>
<style scoped>
.w-mc {
  width: max-content;
}
</style>
