<template>
  <b-badge
    v-if="data.value"
    :class="`rarity-bg-${data.value}`"
  >
    {{ data.value }}
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'VxCellRarityBadge',
  components: { BBadge },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
