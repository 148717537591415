<template>
  <div v-if="!href">
    <vx-no-info-dash v-if="!showLabelWithoutHref" />
    <div
      v-else
      class="d-flex flex-column font-weight-bold"
    >
      {{ label }}
      <small v-if="subLabel" class="text-muted">{{ subLabel }}</small>
    </div>
  </div>
  <b-link
    v-else
    v-bind="linkProps"
    class="d-flex flex-column font-weight-bold"
  >
    {{ label }}
    <small v-if="subLabel" class="text-muted">{{ subLabel }}</small>
  </b-link>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { tdAttrParse } from '@/services/table'
import { isObject } from 'lodash'
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'VxCellLink',

  components: { VxNoInfoDash, BLink },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup ({ data }) {
    const { href, label, subLabel, showLabelWithoutHref = false } = tdAttrParse(data)
    const linkProps = isObject(href) ? { to: href } : { href }

    return {
      label: label || 'Link',
      subLabel,
      href,
      linkProps,
      showLabelWithoutHref
    }
  }
}
</script>
