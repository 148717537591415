<template>
  <vx-no-info-dash v-if="!value" />
  <div
    v-else
    class="text-truncate"
    :class="maxWidthClass"
  >
    <span v-b-tooltip.hover.top="tooltipText">{{ value }}</span>
  </div>
</template>

<script>
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { isUndefined } from 'lodash'
import { tdAttrParse } from '@/services/table'

export default {
  name: 'VxCellTooltip',
  components: { VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const {
      maxWidthClass = 'vx-cell-tooltip-max-width',
      tooltipText
    } = tdAttrParse(data)

    return {
      value: data.value,
      tooltipText: isUndefined(tooltipText) ? data.value : tooltipText,
      maxWidthClass
    }
  }
}
</script>

<style lang="scss" scoped>
.vx-cell-tooltip-max-width {
  max-width: 180px;
}
</style>
