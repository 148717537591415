import { computed, ref, watch, nextTick } from '@vue/composition-api'
import { getRandomString } from '@/services/utils'

/**
 * TODO: set all allowed properties for this table builder
 * Column properties = {
 *   sortable: Enable sorting on this column. Default false
 *   component: Vue component with custom cell
 *   label: Title for column
 *   headerTitle: Tooltip for label
 *   formatter: Formatter callback (value, key, item)
 * }
 */

/**
 * @param props
 * @param ctx
 * @returns {{
 *   head: (function(*): string),
 *   innerValue: WritableComputedRef<*>,
 *   loadingHeight: Ref<UnwrapRef<string>>,
 *   tableRef: null,
 *   headColumns: *,
 *   refreshTable: refreshTable,
 *   selectedRows: ComputedRef<unknown>,
 *   onRowSelected: onRowSelected,
 *   cell: (function(*): string),
 *   paginatedItems: ComputedRef<unknown>,
 *   sortableEmitter: (function(*): *)
 * }}
 */
export default function useVxTableBody (props, ctx) {
  const tableRef = ref(null)
  const tableId = getRandomString()

  const responsive = !props.stickyHeaderFull
  const classes = {
    'sticky-header-full': props.stickyHeaderFull
  }

  const innerValue = computed({
    get: () => props.value,
    set: (value) => {
      ctx.emit('input', value)
    }
  })

  // TODO: find another way to pass selectable helpers to head component
  const selectedRows = computed(() => tableRef.value?.selectedRows || [])
  const paginatedItems = computed(() => tableRef.value?.paginatedItems || [])

  const filteredItemsLength = computed(() => tableRef.value?.filteredItems.length || 0)

  const refreshTable = () => {
    tableRef.value.refresh()
  }

  const sortableEmitter = (sortedItems) => ctx.emit('sortableCallback', sortedItems)

  // TODO: height of previous page for loading not correct
  const loadingHeight = ref('auto')
  watch(
    () => props.loading,
    async (value) => {
      if (value) return

      await nextTick()
      const height = tableRef.value?.$refs?.tbody.$el.clientHeight
      loadingHeight.value = height ? `${height}px` : 'auto'
    },
    { immediate: true }
  )

  const cell = (key) => {
    return `cell(${key})`
  }

  const head = (key) => {
    return `head(${key})`
  }

  const headColumns = computed(() => props.columns.filter(({ headComponent }) => !!headComponent))

  const onRowSelected = (items) => {
    ctx.emit('row-selected', items)
  }

  return {
    tableId,
    tableRef,
    refreshTable,

    innerValue,

    loadingHeight,
    responsive,
    classes,

    cell,

    head,
    headColumns,

    selectedRows,
    paginatedItems,
    onRowSelected,

    sortableEmitter,

    filteredItemsLength
  }
}
