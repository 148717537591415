<template>
  <small class="text-muted">
    —
  </small>
</template>

<script>
export default {
  name: 'VxNoInfoDash'
}
</script>
