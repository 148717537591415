<template>
  <vx-checkbox
    :value="value"
    :indeterminate="indeterminate"
    :name="name"
    class="cursor-pointer"
    @input="update"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import { VxCheckbox } from '@/components/form'

export default {
  name: 'VxHeadCellCheckbox',
  components: {
    VxCheckbox
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    selectedRows: {
      type: Array,
      required: true
    },
    paginatedItems: {
      type: Array,
      required: true
    },
    tableId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      tableId,
      data: {
        clearSelected,
        selectAllRows,
        field: { key }
      }
    } = props

    const name = `${tableId}-${key}`

    const selectedCount = computed(() => {
      return props.selectedRows.filter(value => value).length
    })
    const allItemsCount = computed(() => props.paginatedItems.length || 0)

    const indeterminate = computed(() => {
      return selectedCount.value > 0 && selectedCount.value < allItemsCount.value
    })

    const value = computed(() => allItemsCount.value
      ? selectedCount.value === allItemsCount.value
      : false
    )

    const update = async (value) => {
      if (indeterminate.value) {
        if (value) selectAllRows()
        return
      }

      value ? selectAllRows() : clearSelected()
    }

    return {
      name,
      value,
      indeterminate,
      selectedCount,
      allItemsCount,
      update
    }
  }
}
</script>
