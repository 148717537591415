<template>
  <div class="d-flex justify-content-between align-items-center">
    <span class="mr-1">{{ status }}</span>
    <vx-button
      v-if="canCancel"
      v-b-tooltip="'Cancel drop'"
      :disabled="!canUpdate"
      :loading="loading"
      size="sm"
      variant="danger"
      icon="XIcon"
      @click="cancel"
    />
  </div>
</template>

<script>
import { drops, dropStatuses, useResource } from '@/services/resources'
import { VxButton } from '@/components/buttons'

export default {
  name: 'VxCellDropStatus',
  components: { VxButton },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  setup ({ data: { item: { id, status } }, refreshData }) {
    const {
      can: canUpdate,
      callRequest: updateDropStatus,
      loading
    } = useResource(drops.updateStatus)
    const canCancel = canUpdate && status !== dropStatuses.canceled

    const cancel = async () => {
      const [err] = await updateDropStatus({
        urlParams: { id },
        data: { status: dropStatuses.canceled }
      })
      if (!err) {
        refreshData()
      }
    }

    return {
      status,
      canUpdate,
      canCancel,
      loading,
      cancel
    }
  }
}
</script>
