<template>
  <span>
    <feather-icon
      icon="MenuIcon"
      size="18"
      class="draggable-item cursor-move"
    />
    <span v-if="value" class="ml-1">{{ value }}</span>
  </span>
</template>

<script>
export default {
  name: 'VxCellDraggable',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value } }) {
    return { value }
  }
}
</script>
