<template>
  <b-media v-if="tdAttr.href" vertical-align="center" class="align-items-center w-mc">
    <template #aside>
      <b-img
        fluid
        rounded
        alt="img"
        :src="tdAttr.href"
        :style="style"
      />
    </template>
    <div class="d-flex flex-column">
      <b-link
        v-if="tdAttr.link"
        :to="tdAttr.link"
        class="font-weight-bold text-nowrap"
      >
        {{ tdAttr.label || 'Link' }}
      </b-link>
      <span v-else class="font-weight-bold text-nowrap">{{ tdAttr.label || 'Link' }}</span>
      <small v-if="tdAttr.id" class="text-muted"> #{{ tdAttr.id }}</small>
    </div>
  </b-media>
</template>

<script>
import { BImg, BMedia, BLink } from 'bootstrap-vue'
import { tdAttrParse } from '@/services/table'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellImageWithTitle',
  components: { BImg, BMedia, BLink },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const tdAttr = computed(() => {
      return tdAttrParse(props.data)
    })

    const style = {
      width: tdAttr.value.width || '80px',
      height: tdAttr.value.height || '60px'
    }

    return {
      tdAttr,
      style
    }
  }
}
</script>
<style scoped>
.w-mc {
  width: max-content;
}
</style>
