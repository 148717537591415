<template>
  <div class="d-flex justify-content-between align-items-center">
    <b-badge
      :id="popoverId"
      :variant="color"
      class="mr-75"
    >
      {{ status }}
    </b-badge>
    <b-popover
      :target="popoverId"
      triggers="hover"
      boundary="viewport"
      placement="top"
    >
      <pre class="popover-content">{{ popoverText }}</pre>
    </b-popover>
    <div class="d-flex align-items-center">
      <vx-button-copy
        v-b-tooltip.hover.top="'Copy metadata'"
        :text-to-copy="popoverText"
        class="mr-25"
      />
      <vx-button
        icon="RefreshCwIcon"
        size="sm"
        :can="canUpdateStatus"
        :loading="updateStatusLoading"
        @click="updateStatus"
      />
    </div>
  </div>
</template>

<script>
import { notificationMessages, payments, paymentStatusColors, paymentStatuses, useResource } from '@/services/resources'
import { isEmpty } from 'lodash'
import { VxButton, VxButtonCopy } from '@/components/buttons'
import { useToast } from '@/plugins/toastification'
import { BBadge, BPopover } from 'bootstrap-vue'
import { httpMethods } from '@/services/api/consts'

export default {
  name: 'VxCellPaymentStatus',
  components: { VxButtonCopy, VxButton, BBadge, BPopover },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  setup ({ data, refreshData }) {
    const { index, item: { id, status = paymentStatuses.error, metadata } } = data
    const toast = useToast()
    const popoverId = `badge-status-${index}`
    const {
      can: canUpdateStatus,
      callRequest,
      loading: updateStatusLoading,
      entity
    } = useResource(payments.updateStatus)

    const popoverText = isEmpty(metadata) ? 'No data available' : metadata

    const updateStatus = async () => {
      const [err, res] = await callRequest({ urlParams: { id } })

      if (err) {
        toast.error('There is an error while status updating')
        return
      }

      const { data } = res

      if (data.id) {
        toast.success(notificationMessages[httpMethods.put]({ id: data.id, entity }))
        refreshData()
        return
      }

      if (data.message) {
        data.success ? toast.success(data.message) : toast.error(data.message)
      }
    }

    return {
      status,
      color: paymentStatusColors[status] || 'primary',
      popoverId,
      popoverText,
      updateStatus,
      canUpdateStatus,
      updateStatusLoading
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .popover-body {
  overflow: auto;
  max-height: 85vh;

  pre {
    margin-bottom: 0;
  }
}
</style>
