<template>
  <div class="d-flex align-items-center">
    <feather-icon
      icon="CircleIcon"
      class="mr-1"
      :class="color"
    />
    <div
      v-if="formattedLastActiveAt"
      class="d-flex flex-column"
    >
      <div v-b-tooltip.hover.top="'Last active datetime'">
        {{ formattedLastActiveAt }}
      </div>
      <div
        v-b-tooltip.hover.top="'Difference between now and last active'"
        :class="color"
      >
        {{ difference }} sec
      </div>
    </div>
  </div>
</template>

<script>
import { lastActiveIconColor } from '@/views/markets/botService'
import { dateFormat } from '@/services/utils'

export default {
  name: 'VxCellBotLastActiveTime',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value } }) {
    const { color, difference } = lastActiveIconColor(value)
    const formattedLastActiveAt = dateFormat(value)

    return {
      color,
      difference,
      formattedLastActiveAt
    }
  }
}
</script>
