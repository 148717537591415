<template>
  <vx-no-info-dash v-if="!json" />
  <vx-json-viewer
    v-else
    :value="json"
    :expand-depth="4"
    expanded
    copyable
  />
</template>

<script>
import VxNoInfoDash from '@/components/VxNoInfoDash'
import VxJsonViewer from '@/components/VxJsonViewer'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellJsonViewer',
  components: { VxJsonViewer, VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const json = computed(() => props.data.value)
    return {
      json
    }
  }
}
</script>
