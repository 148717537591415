<template>
  <vx-no-info-dash v-if="!drops.length" />
  <div v-else>
    <div
      v-for="drop in drops"
      :key="drop.id"
      class="d-flex flex-column font-weight-bold"
    >
      <b-link :to="dropRoute(drop)">
        {{ drop.item.title }}
      </b-link>
      <small class="text-muted">
        #{{ drop.id }}
      </small>
      <small v-if="showPrice" class="text-muted">
        {{ moneyFormat(drop.main_price) }}
      </small>
    </div>
  </div>
</template>

<script>
import { moneyFormat, toArray } from '@/services/utils'
import { buildRoute, path } from '@/router'
import { BLink } from 'bootstrap-vue'
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'VxCellDrop',
  components: { VxNoInfoDash, BLink },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value, field: { tdAttr = {} } } }) {
    const { showPrice = false } = tdAttr
    const drops = toArray(value)
    const dropRoute = ({ id }) => {
      return buildRoute(path.drops, { query: { id } })
    }

    return {
      drops,
      dropRoute,
      showPrice,
      moneyFormat
    }
  }
}
</script>
