<template>
  <vx-no-info-dash v-if="!time" />
  <div v-else>
    <div>{{ time }}</div>
    <small v-if="lifetime" class="font-weight-bolder">
      ~ {{ lifetime }}
    </small>
  </div>
</template>

<script>
import { dateFormat, parseISODate } from '@/services/utils'
import { tdAttrParse } from '@/services/table'
import { formatDistanceStrict } from 'date-fns'
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellDatetimeRange',
  components: { VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const { start, finish } = tdAttrParse(data)

    const time = computed(() => {
      if (!start || !finish) return null
      return `${dateFormat(start)} - ${dateFormat(finish)}`
    })

    const lifetime = computed(() => {
      if (!start || !finish) return null
      return formatDistanceStrict(parseISODate(start), parseISODate(finish), { unit: 'hour' })
    })

    return {
      time,
      lifetime
    }
  }
}
</script>
