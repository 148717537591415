<template>
  <div>
    <b-card v-if="hasFilters && can">
      <vx-table-filters
        v-model="queryParams.filter"
        :filters="filterDefs"
      />
    </b-card>
    <vx-card
      :can="can"
      no-body
      card-class="mb-0"
    >
      <template v-if="$slots.title" #title>
        <slot name="title" />
      </template>
      <template v-if="$slots.actions" #actions>
        <slot name="actions" />
      </template>

      <vx-table-body
        v-model="queryParams.sort"
        :columns="columns"
        :items="items"
        :loading="loading"
        :primary-key="primaryKey"
        :refresh-data="refresh"
        :sortable="sortable"
        :reset-sortable-cache="resetSortableCache"
        :details-component="detailsComponent"
        v-bind="$attrs"
        @sortableCallback="sortableEmitter"
      />
      <template v-if="!hidePagination && hasPagination && can" #footer>
        <vx-table-pagination
          v-model="queryParams.pagination"
          :params="paginationParams"
          :hide-per-page="hidePerPage"
        />
      </template>
    </vx-card>
  </div>
</template>

<script>
import VxTableBody from '@/components/table/vx-table-body/VxTableBody'
import VxTableFilters from '@/components/table/vx-table-filters/VxTableFilters'
import VxTablePagination from '@/components/table/vx-table-pagination/VxTablePagination'
import VxTableSort from '@/components/table/vx-table-sort/VxTableSort'
import useVxTable from '@/components/table/vx-table/useVxTable'
import { BCard } from 'bootstrap-vue'
import VxCard from '@/components/VxCard'

export default {
  name: 'VxTable',

  components: {
    VxTableBody,
    VxTableFilters,
    VxTablePagination,
    VxTableSort,
    VxCard,
    BCard
  },

  props: {
    columns: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      default: () => ([])
    },
    resource: {
      type: Object,
      required: true
    },
    dataMapper: {
      type: Function,
      default: null
    },
    suppressRouteHandling: {
      type: Boolean
    },
    hidePerPage: {
      type: Boolean
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: null
    },
    prefetch: {
      type: Function,
      default: null
    },
    sortable: {
      type: Boolean,
      default: false
    },
    resetSortableCache: {
      type: Boolean,
      default: false
    },
    detailsComponent: {
      type: Object,
      default: null
    }
  },

  setup (props, ctx) {
    return useVxTable(props, ctx)
  }
}
</script>
