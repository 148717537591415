<template>
  <vx-lazy v-if="data.value">
    <vx-cell-permissions-checkbox-inner
      v-if="editMode"
      :permissions-id="data.value.id"
    />
    <vx-cell-permissions-icon-status
      v-else
      :permissions-id="data.value.id"
    />
  </vx-lazy>
</template>

<script>

import VxLazy from '@/components/VxLazy'
import VxCellPermissionsIconStatus from '@/components/table/vx-table-cells/vx-cell-permissions/VxCellPermissionsIconStatus'
import VxCellPermissionsCheckboxInner from '@/components/table/vx-table-cells/vx-cell-permissions/VxCellPermissionsCheckboxInner'

export default {
  name: 'VxCellPermissionsCheckbox',
  components: {
    VxLazy,
    VxCellPermissionsIconStatus,
    VxCellPermissionsCheckboxInner
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    return {
      editMode: props.data.field.tdAttr.editMode
    }
  }
}
</script>
