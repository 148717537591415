<template>
  <div
    ref="cellRef"
    class="w-100 h-100 cell-input"
    :class="{ 'cursor-pointer': canUpdate }"
    @click="setEditMode(true)"
  >
    <span v-if="!editMode">{{ data.value }}</span>
    <div
      v-else
      :style="cellStyle"
      class="cell-input-wrapper"
    >
      <vx-form
        v-slot="{ loading }"
        :resource="formResource"
        @submit="handleSubmit"
      >
        <vx-input
          v-model="formValue.predefined_chance"
          :rules="rules.predefined_chance"
          name="predefined_chance"
          type="number"
        >
          <template #append>
            <vx-button
              v-b-tooltip="'Save'"
              :loading="loading"
              variant="transparent"
              size="sm"
              icon="SaveIcon"
              type="submit"
            />
          </template>
        </vx-input>
      </vx-form>
    </div>
  </div>
</template>

<script>
import { VxInput, VxForm } from '@/components/form'
import { reactive, ref } from '@vue/composition-api'
import { onClickOutside } from '@vueuse/core'
import { cases, passDataToResource, useResource } from '@/services/resources'
import { BFormInput } from 'bootstrap-vue'
import { VxButton } from '@/components/buttons'

export default {
  name: 'VxCellItemChanceInput',
  components: {
    VxForm,
    VxInput,
    VxButton,
    BFormInput
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  setup (props) {
    const {
      data: { item: { chest_id, item_id } },
      refreshData
    } = props

    const cellRef = ref(null)
    const editMode = ref(false)

    const formResource = passDataToResource(cases.updateItem, {
      requestParams: {
        urlParams: { chestId: chest_id, itemId: item_id }
      }
    })
    const { can: canUpdate } = useResource(formResource)

    const setEditMode = (value) => {
      if (!canUpdate || value === editMode.value) return

      if (value) {
        formValue.predefined_chance = props.data.value
        setCellStyle()
      }

      editMode.value = value
    }

    onClickOutside(cellRef, () => {
      if (!editMode.value) return

      setEditMode(false)
    })

    const formValue = reactive({
      predefined_chance: null
    })

    const rules = {
      predefined_chance: {
        min_value: 1,
        integer: true
      }
    }

    const cellStyle = {
      width: '100px'
    }

    const setCellStyle = () => {
      cellStyle.width = `${cellRef.value.clientWidth}px`
    }

    const handleSubmit = ([err]) => {
      if (err) return

      refreshData()
    }

    return {
      cellRef,
      editMode,
      setEditMode,

      formResource,
      canUpdate,
      formValue,
      rules,

      cellStyle,
      handleSubmit
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended.scss';

.cell-input {
  min-height: 25px;

  &-wrapper {
    position: absolute;
    border: $border-width $table-border-color solid;
    padding: 2px;
    background-color: $table-hover-bg;

    ::v-deep .form-group {
      margin-bottom: 0;

      .input-group-text {
        padding: 0;
      }
    }
  }
}
</style>
