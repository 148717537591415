<template>
  <date-picker
    :value="value"
    :clearable="false"
    confirm
    @confirm="confirm"
  >
    <template #input>
      <vx-button
        size="sm"
        :can="can"
        :loading="loading"
        :variant="variant"
      >
        {{ formattedValue }}
      </vx-button>
    </template>
    <template #icon-calendar>
      <span />
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { dateFormat, dateFormats } from '@/services/utils'
import VxButton from '@/components/buttons/VxButton'
import { isBefore, isSameDay } from 'date-fns'
import { antifraud, useResource } from '@/services/resources'

export default {
  name: 'VxCellCredentialsEndsAt',
  components: { VxButton, DatePicker },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  setup ({ data, refreshData }) {
    const { can, loading, callRequest } = useResource(antifraud.updatedCredential)

    const value = new Date(data.value)
    const variant = isBefore(value, new Date()) ? 'warning' : 'success'
    const formattedValue = dateFormat(data.value, dateFormats.date)

    const confirm = async (date) => {
      if (isSameDay(date, value)) return

      const { id, is_active } = data.item
      const payload = {
        urlParams: { id },
        data: { ends_at: date, is_active }
      }
      const [err] = await callRequest(payload)

      if (!err) {
        refreshData()
      }
    }

    return {
      value,
      variant,
      formattedValue,
      can,
      loading,
      confirm
    }
  }
}
</script>
