export const buttons = {
  view: {
    label: 'View',
    icon: 'EyeIcon'
  },
  add: {
    label: 'Add',
    variant: 'primary',
    icon: 'PlusIcon'
  },
  edit: {
    label: 'Edit',
    icon: 'EditIcon'
  },
  delete: {
    label: 'Delete',
    variant: 'danger',
    icon: 'TrashIcon'
  },
  block: {
    label: 'Block',
    variant: 'danger',
    icon: 'SlashIcon'
  },
  appeal: {
    label: 'Appeal',
    variant: 'success',
    icon: 'ThumbsUpIcon'
  },
  inspect: {
    label: 'Inspect'
  },
  undoInspect: {
    label: 'Undo Inspect'
  },
  turnOn: {
    label: 'Disabled',
    variant: 'danger'
  },
  turnOff: {
    label: 'Enabled',
    variant: 'success'
  },
  approve: {
    label: 'Approve',
    variant: 'success',
    icon: 'ThumbsUpIcon'
  },
  reject: {
    label: 'Reject',
    variant: 'danger',
    icon: 'XIcon'
  },
  active: {
    label: 'Active',
    variant: 'success'
  },
  banned: {
    label: 'Banned',
    variant: 'danger'
  },
  tradingOn: {
    label: 'Trading On',
    variant: 'success',
    icon: 'BarChart2Icon'
  },
  tradingOff: {
    label: 'Trading Off',
    variant: 'danger',
    icon: 'BarChart2Icon'
  },
  pricingOn: {
    label: 'Pricing On',
    variant: 'success',
    icon: 'DollarSignIcon'
  },
  pricingOff: {
    label: 'Pricing Off',
    variant: 'danger',
    icon: 'DollarSignIcon'
  }
}
