<template>
  <b-table
    ref="tableRef"
    v-sortable="{ sortable, resetSortableCache, sortableEmitter }"
    :items="items"
    :fields="columns"
    :busy="loading"
    :sort-by.sync="innerValue.sortBy"
    :sort-desc.sync="innerValue.sortDesc"
    :primary-key="primaryKey"
    show-empty
    empty-text="No matching records found"
    no-local-sorting
    sort-direction="desc"
    :responsive="responsive"
    striped
    class="vx-table-body mb-0 position-relative"
    :class="classes"
    v-bind="$attrs"
    @row-selected="onRowSelected"
  >
    <template v-for="(column, idx) in columns" #[cell(column.key)]="cellData">
      <component
        :is="cellData.field.component"
        v-if="cellData.field.component"
        :key="idx"
        :data="cellData"
        :refresh-data="refreshData"
        :table-id="tableId"
      />
      <span v-else :key="idx">{{ cellData.value }}</span>
    </template>

    <template v-for="(column, idx) in headColumns" #[head(column.key)]="cellData">
      <component
        :is="cellData.field.headComponent"
        :key="idx"
        :data="cellData"
        :refresh-data="refreshData"
        :selected-rows="selectedRows"
        :paginated-items="paginatedItems"
        :table-id="tableId"
      />
    </template>

    <!-- Loading -->
    <template #table-busy>
      <div
        class="d-flex justify-content-center align-items-center text-primary"
        :style="{ height: loadingHeight }"
      >
        <b-spinner class="align-middle" />
        <strong class="ml-1">Loading...</strong>
      </div>
    </template>

    <template v-if="detailsComponent" #row-details="row">
      <component
        :is="detailsComponent"
        :data="row"
        :refresh-data="refreshData"
      />
    </template>
  </b-table>
</template>

<script>
import { BSpinner, BTable } from 'bootstrap-vue'
import useVxTableBody from '@/components/table/vx-table-body/useVxTableBody'
import { sortable } from '@/components/table/vx-table-body/directives'

export default {
  name: 'VxTableBody',
  directives: { sortable },
  components: {
    BSpinner,
    BTable
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => ([])
    },
    columns: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    primaryKey: {
      type: String,
      required: true
    },
    refreshData: {
      type: Function,
      default: () => {}
    },
    sortable: {
      type: Boolean,
      default: false
    },
    resetSortableCache: {
      type: Boolean,
      default: false
    },
    detailsComponent: {
      type: Object,
      default: null
    },
    stickyHeaderFull: Boolean
  },

  emits: ['input', 'sortableCallback'],

  setup (props, ctx) {
    return useVxTableBody(props, ctx)
  }
}
</script>

<style lang="scss" scoped>
.vx-table-body {
  border-radius: inherit;

  &.sticky-header-full ::v-deep {
    thead > tr {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}
</style>
