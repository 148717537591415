<template>
  <vx-no-info-dash v-if="isInvalidValue" />
  <json-viewer
    v-else
    v-bind="$attrs"
    :theme="theme"
    :value="value"
  />
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import { isNull, isUndefined } from 'lodash'
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { computed } from '@vue/composition-api'
import { useSkin } from '@/services/app'

export default {
  name: 'VxJsonViewer',
  components: { VxNoInfoDash, JsonViewer },
  props: {
    value: {
      type: [Object, Array, String, Number],
      default: null
    }
  },
  setup ({ value }) {
    const { skin, skins } = useSkin()

    const jsonEditorThemes = {
      [skins.dark]: 'jv-dark',
      [skins.bordered]: 'jv-light'
    }

    const theme = computed(() => jsonEditorThemes[skin.value])

    return {
      theme,
      skin,
      isInvalidValue: isNull(value) || isUndefined(value)
    }
  }
}
</script>
