<template>
  <div class="h-100 w-100 d-flex justify-content-center">
    <b-form-checkbox
      v-if="editMode"
      :id="name"
      v-model="innerValue"
      :indeterminate="indeterminate"
      @change="handleChange"
    />
    <feather-icon
      v-else
      size="22"
      :icon="iconAttr.icon"
      :class="iconAttr.class"
    />
  </div>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'VxCellPermissionsRowCheckbox',
  components: {
    BFormCheckbox
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    tableId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { data: { index }, tableId } = props

    const { innerItems, editMode, getIdsFromRow, updateManyItems } = inject('permissionsStore')

    const name = `${tableId}-${index}`

    const ids = computed(() => {
      return getIdsFromRow(props.data.item)
    })

    const checkedCount = computed(() => {
      return ids.value.filter(id => innerItems[id]).length
    })

    const innerValue = computed({
      get: () => checkedCount.value === ids.value.length,
      set: () => {}
    })

    const indeterminate = computed(() => {
      return checkedCount.value > 0 && checkedCount.value < ids.value.length
    })

    const handleChange = (value) => {
      updateManyItems(ids.value, value)
    }

    const iconAttrs = {
      success: {
        icon: 'CheckIcon',
        class: 'text-success'
      },
      danger: {
        icon: 'XIcon',
        class: 'text-danger'
      },
      minus: {
        icon: 'MinusIcon',
        class: 'text-warning'
      }
    }

    const iconAttr = computed(() => {
      return innerValue.value
        ? iconAttrs.success
        : indeterminate.value
          ? iconAttrs.minus
          : iconAttrs.danger
    })

    return {
      innerValue,
      indeterminate,
      handleChange,
      name,
      editMode,
      iconAttr
    }
  }
}
</script>
