<template>
  <vx-no-info-dash v-if="!data.value" />
  <div v-else>
    <div v-for="(value, key) in data.value" :key="key">
      {{ key }}: {{ value }}
    </div>
  </div>
</template>

<script>
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'VxCellPre',
  components: {
    VxNoInfoDash
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
