<template>
  <feather-icon
    size="22"
    :icon="iconAttr.icon"
    :class="iconAttr.class"
  />
</template>

<script>
import { computed, inject } from '@vue/composition-api'

export default {
  name: 'VxCellPermissionsIconStatus',
  props: {
    permissionsId: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const { innerItems } = inject('permissionsStore')

    const innerValue = computed(() => innerItems[props.permissionsId])

    const iconAttrs = {
      success: {
        icon: 'CheckIcon',
        class: 'text-success'
      },
      danger: {
        icon: 'XIcon',
        class: 'text-danger'
      }
    }

    const iconAttr = innerValue.value ? iconAttrs.success : iconAttrs.danger

    return {
      iconAttr
    }
  }
}
</script>
