<template>
  <vx-lazy>
    <vx-cell-payment-country-checkbox-inner
      v-if="editMode"
      :column-key="data.field.key"
      :item="data.item"
    />
    <vx-cell-payment-country-icon-status
      v-else
      :item="data.item"
      :column-key="data.field.key"
    />
  </vx-lazy>
</template>

<script>
import VxCellPaymentCountryIconStatus from '@/components/table/vx-table-cells/vx-cell-payment-countries/VxCellPaymentCountryIconStatus'
import VxCellPaymentCountryCheckboxInner from '@/components/table/vx-table-cells/vx-cell-payment-countries/VxCellPaymentCountryCheckboxInner'
import VxLazy from '@/components/VxLazy'

export default {
  name: 'VxCellPaymentCountryCheckbox',
  components: {
    VxLazy,
    VxCellPaymentCountryCheckboxInner,
    VxCellPaymentCountryIconStatus
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    return {
      editMode: props.data.field.tdAttr.editMode
    }
  }
}
</script>
