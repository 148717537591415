import { differenceInSeconds, parseISO } from 'date-fns'

export const lastActiveIconColor = (lastActive) => {
  const colors = {
    success: 'text-success',
    warning: 'text-warning',
    error: 'text-danger'
  }

  if (!lastActive) {
    return {
      color: colors.error,
      difference: null
    }
  }

  const successTime = 5
  const warningTime = 15
  const difference = differenceInSeconds(new Date(), parseISO(lastActive))
  let color = ''

  switch (true) {
    case (difference <= successTime):
      color = colors.success
      break
    case (difference <= warningTime):
      color = colors.warning
      break
    default:
      color = colors.error
  }

  return { color, difference }
}
