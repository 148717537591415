<template>
  <b-row>
    <!-- Counter -->
    <b-col
      cols="12"
      sm="4"
      class="d-flex align-items-center justify-content-center justify-content-sm-start"
    >
      <span class="text-muted">
        Showing {{ params.from }} to {{ params.to }} ({{ params.to - params.from + 1 }}) of {{ params.total }} entries
      </span>
    </b-col>

    <!-- Per page select -->
    <b-col
      cols="12"
      sm="4"
      class="d-flex align-items-center justify-content-center"
    >
      <span v-if="!hidePerPage">
        <span class="text-muted">Show</span>
        <!-- TODO: add top position for dropdown -->
        <vx-select
          v-model="innerValue.perPage"
          :options="perPageOptions"
          :clearable="false"
          name="perPage"
          class="d-inline-block width-100 mx-50 per-page-select"
        />
        <span class="text-muted">entries</span>
      </span>
    </b-col>

    <!-- Pagination -->
    <b-col
      cols="12"
      sm="4"
      class="d-flex align-items-center justify-content-center justify-content-sm-end"
    >
      <b-pagination
        v-model="innerValue.page"
        :total-rows="params.total"
        :per-page="innerValue.perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BPagination, BRow } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import VxSelect from '@/components/form/VxSelect'

// TODO: rewrite to flex extend b-col, need for responsive and hidePerPage
export default {
  name: 'VxTablePagination',

  components: {
    BRow,
    BCol,
    BPagination,
    VxSelect
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    hidePerPage: {
      type: Boolean
    }
  },

  emits: ['input'],

  setup (props, ctx) {
    const perPageOptions = [10, 25, 50, 100]

    const innerValue = computed({
      get: () => props.value,
      set: (value) => {
        ctx.emit('input', value)
      }
    })

    return {
      innerValue,
      perPageOptions
    }
  }
}
</script>

<style scoped>
.per-page-select {
  margin-bottom: 0;
}
</style>
