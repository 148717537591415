<template>
  <vx-no-info-dash v-if="isEmpty(values)" />
  <div v-else class="d-flex flex-column vertical-list">
    <div
      v-for="({ inspection_name, value, reference }, id) in values"
      :key="id"
      v-b-tooltip.top.html="tooltipTemplate(reference)"
      class="d-flex flex-row justify-content-between"
    >
      <span>
        {{ labelFormat(inspection_name) }}
      </span>
      <div :class="valueClass(value)">
        <span class="font-weight-bold align-middle">
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { isEmpty } from 'lodash'
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { labelFormat } from '@/services/utils'

export default {
  name: 'VxCellAntifraudPoints',
  components: { VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value } }) {
    const tooltipTemplate = (value) => {
      if (isEmpty(value)) return

      return `<div class="font-weight-bold">${value}</div>`
    }

    const valueClass = (value) => {
      return value > 0 ? 'text-success' : 'text-danger'
    }

    return {
      values: value,

      isEmpty,
      labelFormat,

      valueClass,
      tooltipTemplate
    }
  }
}
</script>
