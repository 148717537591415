<template>
  <div class="d-flex align-items-center">
    <b-form-checkbox
      v-if="editMode"
      :id="name"
      v-model="innerValue"
      :indeterminate="indeterminate"
      @change="handleChange"
    />
    <div>
      <div>{{ label }}</div>
      <div>{{ checked }}/{{ itemsCount }}</div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from '@vue/composition-api/dist/vue-composition-api'
import { BFormCheckbox } from 'bootstrap-vue'
import { upperCase } from 'lodash'

export default {
  name: 'VxHeadCellPaymentCountryCheckbox',
  components: {
    BFormCheckbox
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { data: { column, label, field: { key } } } = props
    const { changeColumn, checkedCount, itemsCount, editMode } = inject('checkboxStore')

    const checked = computed(() => checkedCount[key])

    const name = `head-${key}-${column}`

    const innerValue = computed({
      get: () => checked.value === itemsCount.value,
      set: () => {}
    })

    const indeterminate = computed(() => {
      return checked.value > 0 && checked.value < itemsCount.value
    })

    const handleChange = (value) => {
      changeColumn(value, key)
    }

    return {
      innerValue,
      indeterminate,
      handleChange,
      name,
      label: upperCase(label),

      editMode,

      checked,
      itemsCount
    }
  }
}
</script>
