<template>
  <div v-if="bonus">
    <b-link :to="url" class="text-nowrap">
      {{ label }}
    </b-link>
    <div v-html="bonusDescription" />
    <b-badge v-if="gatewayMinegate" :variant="bonusStatusColor">
      {{ bonusStatus }}
    </b-badge>
  </div>
  <vx-no-info-dash v-else />
</template>

<script>
import { computed } from '@vue/composition-api'
import { permissions } from '@/plugins/acl'
import { bonusStatuses } from '@/services/resources'
import { addPercentSign, moneyFormat } from '@/services/utils'
import { paymentSystemsMap, bonusTypes } from '@/services/resources/domainsConsts'
import { BLink, BBadge } from 'bootstrap-vue'
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { buildRoute } from '@/router'
import { bonusUrls } from '@/views/bonuses/bonusesService'

export default {
  name: 'VxCellPromocodeBonusInfo',
  components: { BLink, BBadge, VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value } }) {
    const statusColorsMap = {
      [bonusStatuses.desired]: 'primary',
      [bonusStatuses.applied]: 'success',
      [bonusStatuses.ignored]: 'danger'
    }

    const canBonusView = permissions.bonusView

    const url = buildRoute(bonusUrls[value.bonus_type], { query: { id: value.id } })

    const label = value.bonus_type
    const gatewayMinegate = value.gateway === paymentSystemsMap.minegate
    const bonusStatus = value.refill_bonus_status
    const bonusStatusColor = statusColorsMap[bonusStatus]

    const bonusDescription = computed(() => {
      if (!value) return null

      if (label === bonusTypes.refill || value.refill_bonus) {
        const formatValue = value.type === 'fixed'
          ? moneyFormat({ amount: value.value, currency: value.currency_code })
          : addPercentSign(value.value)

        return `<b>${formatValue}</b> for <b>${moneyFormat(value.min_refill_amount)}</b> min refill sum`
      }

      return `<b>${moneyFormat(value.amount)}</b>`
    })

    return {
      canBonusView,
      bonus: value,
      url,
      label,
      gatewayMinegate,
      bonusDescription,
      bonusStatus,
      bonusStatusColor
    }
  }
}
</script>
