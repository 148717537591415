<template>
  <vx-no-info-dash v-if="!value" />
  <div v-else>
    <div v-if="showTime" class="text-nowrap">
      <div>{{ date }}</div>
      <div>{{ time }}</div>
    </div>
    <span
      v-else
      v-b-tooltip.hover.top="time"
      class="text-nowrap"
    >
      {{ date }}
    </span>
  </div>
</template>

<script>
import { dateFormat, dateFormats } from '@/services/utils'
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'VxCellDate',
  components: { VxNoInfoDash },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data: { value, field: { tdAttr = {} } } }) {
    const { showTime = false } = tdAttr
    const [date, time] = dateFormat(value, `${dateFormats.date}|${dateFormats.time}`).split('|')

    return {
      date,
      time,
      value,
      showTime
    }
  }
}
</script>
