<template>
  <feather-icon
    :icon="icon"
    size="16"
  />
</template>

<script>
import { tdAttrParse } from '@/services/table'

export default {
  name: 'VxCellIcon',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup ({ data }) {
    const { icon } = tdAttrParse(data)

    return { icon }
  }
}
</script>
