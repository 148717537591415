<template>
  <vx-no-info-dash v-if="!value" />
  <b-media v-else vertical-align="center">
    <template #aside>
      <b-avatar
        size="32"
        :src="value.avatar"
        :text="avatarText"
      />
    </template>
    <b-link
      :to="playerLink"
      class="font-weight-bold d-block"
    >
      {{ value.name }}
    </b-link>
    <small class="text-muted">{{ subText }}</small>
  </b-media>
</template>

<script>
import { BAvatar, BLink, BMedia } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import { buildRoute, path } from '@/router'
import VxNoInfoDash from '@/components/VxNoInfoDash'

export default {
  name: 'VxCellPlayerAvatar',

  components: {
    VxNoInfoDash,
    BAvatar,
    BMedia,
    BLink
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  setup ({ data: { value, field: { tdAttr = {} } } }) {
    const avatarText = computed(() => {
      if (!value.fullName) return ''
      const nameArray = value.fullName.split(' ')
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    })
    const playerLink = buildRoute(path.player, { params: { id: value.id } })
    const subText = tdAttr.showSteamId ? `#${value.steam_id}` : `#${value.id}`

    return {
      value,
      avatarText,
      subText,
      playerLink
    }
  }
}
</script>
