<template>
  <vx-no-info-dash v-if="!src" />
  <b-img
    v-else
    fluid
    rounded
    alt="img"
    :src="src"
    :style="style"
  />
</template>

<script>
import VxNoInfoDash from '@/components/VxNoInfoDash'
import { tdAttrParse } from '@/services/table'
import { BImg } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

export default {
  name: 'VxCellImage',
  components: { VxNoInfoDash, BImg },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const src = computed(() => {
      const { external_link = props.data.value } = props.data.value
      return external_link
    })
    const { width = null, height = null } = tdAttrParse(props.data)

    const style = {
      width: width || '80px',
      height: height || 'auto'
    }

    return {
      src,
      style
    }
  }
}
</script>
