<template>
  <div class="d-flex justify-content-between align-items-center">
    <b-badge variant="primary">
      {{ status }}
    </b-badge>
    <vx-button
      v-b-tooltip="'Update status'"
      :loading="loading"
      size="sm"
      :can="canUpdate"
      variant="primary"
      icon="RefreshCwIcon"
      @click="update"
    />
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { VxButton } from '@/components/buttons'
import { giftCards, useResource } from '@/services/resources'

export default {
  name: 'VxCellGiftKeyStatus',
  components: { VxButton, BBadge },
  props: {
    data: {
      type: Object,
      required: true
    },
    refreshData: {
      type: Function,
      required: true
    }
  },
  setup ({ data: { item: { status, id } }, refreshData }) {
    const { can: canUpdate, loading, callRequest } = useResource(giftCards.updateKeyStatus)

    const update = async () => {
      const [err] = await callRequest({
        urlParams: { id }
      })
      if (!err) {
        refreshData()
      }
    }

    return {
      status,
      canUpdate,
      loading,
      update
    }
  }
}
</script>
